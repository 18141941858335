@tailwind base;
@tailwind components;
@tailwind utilities;

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.fixed-height {
  height: 580px;
}

/* Primary Brand Colors */
.primary-indigo { background-color: #36208B; }      /* Banners, Headers, Brand Identity */
.primary-amethyst { background-color: #6B19E5; }    /* Primary CTA, Active States */
.primary-aqua { background-color: #C4F1FF; }        /* TBD */
.primary-sky { background-color: #9FE8FF; }         /* TBD */

/* Other Brand Color Variants */
.other-darkViolet { background-color: #542189; }    /* Primary CTA Hover */
.other-grey { background-color: #EBEBEB; }          /* Inactive Components */
.other-lightBlue { background-color: #CDD0F9; }     /* Illustrations */
.other-veryLightBlue { background-color: #E9E9F4; } /* Illustrations, badges */

/* Neutral Brand Colors */
.neutral-black { background-color: #000; }          /* Headings, Labels */
.neutral-blackLighter { background-color: rgba(0, 0, 0, 0.8); }  /* Placeholders */
.neutral-grey { background-color: rgba(0, 0, 0, 0.6); }          /* Tertiary text */
.neutral-greyLighter { background-color: rgba(0, 0, 0, 0.4); }   /* Inactive buttons */
.neutral-greySuperLight { background-color: #F9FAFB; }           /* Form footers */
.neutral-lightBG { background-color: #F9FEFF; }                  /* TBD */
.neutral-white { background-color: #FFFFFF; }                    /* Backgrounds */
.neutral-border { background-color: #D9DBE9; }                  /* Borders */

/* Functional Colors */
.functional-success { background-color: #4EBE82; }      /* Success states */
.functional-error { background-color: #F65C5E; }        /* Error states */
.functional-warning { background-color: #FFB545; }      /* Warning states */
.functional-info { background-color: #2BCFFE; }         /* Info states */

/* Supporting Functional Colors */
.supporting-success { background-color: #E4FFF7; }      /* Supporting success */
.supporting-error { background-color: #FFE4E5; }        /* Supporting error */
.supporting-warning { background-color: #FFF0C8; }      /* Supporting warning */
.supporting-info { background-color: #E2EEFF; }         /* Supporting info */

/* Gradients */
.gradient-purple { background: linear-gradient(90deg, #6B19E5 0%, #542189 100%); }
.gradient-amethystSky { background: linear-gradient(90deg, #6B19E5 0%, #9FE8FF 100%); }
.gradient-aqua { background: linear-gradient(90deg, #C4F1FF 0%, #9FE8FF 100%); }

.bg-success {
  background-color: #017b92 !important;
}

.bg-teal {
  background-color: #004552 !important;
}

.bg-indigo {
  background-color: #172849 !important;
}

.bg-amethyst {
  background-color: #392f75 !important;
}

.bg-supporting-teal {
  background-color: #017b92 !important;
}

.bg-supporting-ash {
  background-color: #4e4b66 !important;
}

.bg-medium-warning {
  background-color: #e9d786 !important;
}

.bg-medium-ash {
  background-color: #a0a3bd !important;
}

.bg-light-primary {
  background-color: #d3e3fd !important;
}

.bg-light-ash {
  background-color: #d9dbe9 !important;
}

.bg-plum {
  background-color: #432043 !important;
}

.text-success {
  color: #017b92 !important;
}

.text-amethyst {
  color: #392f75;
}

.text-indigo {
  color: #172849;
}

.text-ash {
  color: #14142b;
}

.text-supporting-cyan {
  color: #99ffff !important;
}

.nav-tabs {
  border: 0 !important;
}

.nav-link {
  color: white !important;
}

.nav-link.active {
  background-color: #f6f6f6 !important;
  border-color: #f6f6f6 !important;
  color: #392f75 !important;
}
